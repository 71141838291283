body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
  color: #ffffff;
  font-family: Geneva, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  padding: 20px;
  text-align: center;
}

h1 {
  color: #ffffff;
}

p {
  color: #ffffff;
}

button {
  background: #444;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
